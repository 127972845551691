<template>
  <v-bottom-navigation v-if="rol==='administrador'" v-model="activeItem" :bg-color="bgColor" mode="fixed">
    <v-btn v-if="role === 'cliente'" @click="navigateTo('ConsultaFoliosView')">
      <v-icon>mdi-file-document-outline</v-icon>
      <span>Consulta de Folios</span>
    </v-btn>

    <v-btn v-if="role === 'cliente'" @click="navigateTo('TimelineView')">
      <v-icon>mdi-timeline-outline</v-icon>
      <span>Consulta de Folios</span>
    </v-btn>

    <v-btn v-if="role === 'cliente'" @click="navigateTo('FaqView')">
      <v-icon>mdi-timeline-outline</v-icon>
      <span>Preguntas Frecuentes</span>
    </v-btn>

    <v-btn v-if="role === 'administrador'" @click="navigateTo('CatalogoMantenimientoView')">
      <v-icon>mdi-wrench</v-icon>
      <span>Catálogo Mantenimiento</span>
    </v-btn>

    <v-btn v-if="role === 'administrador'" @click="$router.push('/dashboard/usuarios')">
      <v-icon>mdi-account-group-outline</v-icon>
      <span>Usuarios</span>
    </v-btn>


    <v-btn v-if="role === 'administrador'" @click="$router.push('/dashboard/cargaarchivos')">
      <v-icon>mdi-chart-line-variant</v-icon>
      <span>Carga de archivos</span>
    </v-btn>
    <v-btn @click="handleLogout">
      <v-icon>mdi-logout-variant</v-icon>
      <span>Salir</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'BottomNavigationMenu',
  props: {
    role: {
      type: String,
      required: true
    },
    bgColor: {
      type: String,
      default: 'white'
    }
  },
  computed: {
    ...mapState(['rol']), // Mapea userRole desde Vuex
  },
  data() {
    return {
      activeItem: null
    };
  },
  methods: {
    ...mapActions(['logout']),
    async handleLogout() {
      await this.logout(); // Llama la acción de logout de Vuex
    },
    navigateTo(routeName) {
      if (this.$router.currentRoute.name !== routeName) {
        this.$router.push({ name: routeName });
      }
    }
  }
};
</script>

<style scoped>
/* Estilos opcionales según necesidades */
</style>
