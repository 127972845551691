<template>
  <v-container class="d-flex align-center justify-center" fill-height>
    <v-card class="pa-5 logout-card">
      <v-card-title>
        <h1 class="text-center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sesión finalizada</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <p>¡Gracias por utilizar el portal de búsqueda de Cor-labs!</p>
        <v-progress-circular indeterminate color="#009496" class="my-5"></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'LogoutView',
  mounted() {
    // Redirige después de 5 segundos (5000 ms)
    setTimeout(() => {
      this.$router.push({name: 'LoginView'}); // Cambia 'LoginView' por el nombre de tu ruta de login
    });
  },
};
</script>

<style scoped>
.logout-card {
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 30vw; /* Ajusta el ancho según sea necesario */
  height: 20vh; /* Ajusta la altura según sea necesario */
}

.v-container {
  background-color: #f5f5f5;
  height: 100vh;
}
</style>
