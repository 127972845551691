
<template>
  <v-container>

    <v-card class="elevation-3"   >
      <v-card-title>
        <v-spacer></v-spacer> <!-- Este espaciador empuja los elementos hacia la derecha -->
        <v-btn type="submit" class="v-btn-geen" color="#009496" text-color="white" @click="handleLogout">
          Salir
        </v-btn>
      </v-card-title>

      <v-flex xs12 sm3 class="mx-3 mb-6"></v-flex>
      <!-- Ajuste: Agregar espacio entre título y contenido width: '300px', backgroundColor: '#e0e0e0', padding: '10px', border: '1px solid #ccc', borderRadius: '4px'-->

      <v-card-text class="search-panel">
    <v-row>
      <v-col>
        <div class="panel-title">Filtro de búsqueda</div>
        <v-form>
          <v-row class="form-row-folio">
            <!-- Campo Folio -->
            <v-col cols="16" sm="6" class="form-group">
              <label for="folio_id">Por folio</label>
              <input
    type="text"
    v-model="folio_id"
    placeholder="Ingrese folio cormac"
    :style="{ width: '700px', backgroundColor: '#e0e0e0',padding: '5px'}" 
  />
            </v-col>
          </v-row>

          <v-row>
            <!-- Campo Fecha de Inicio -->
            <v-col cols="12" sm="6" class="form-group">
              <label for="start_date">Por periódo: Del &nbsp;&nbsp;</label>
              <!--v-text-field
                v-model="start_date"
                type="date"
              ></v-text-field-->
              <input
                type="date"
                v-model="start_date"
                :style="{ width: '300px',backgroundColor: '#e0e0e0',padding: '5px'}"
              />
            </v-col>

            <!-- Campo Fecha de Fin -->
            <v-col cols="12" sm="6" class="form-group-al">
              <label for="end_date">al:&nbsp;&nbsp;</label>
              <!--v-text-field
                v-model="end_date"
                type="date"
              ></v-text-field-->
              <input
                type="date"
                v-model="end_date"
                :style="{width: '300px', backgroundColor: '#e0e0e0',padding: '5px'}"
              />
            </v-col>
          </v-row>

         
        </v-form>
      </v-col>
    </v-row>
   </v-card-text>
   <v-flex xs12 sm3 class="mx-3 mb-6"></v-flex>
   <!-- Botones de Buscar y Limpiar -->

   <v-row>
            <v-col cols="12" class="form-buttons">
              <v-btn type="submit" class="v-btn-geen" color="#009496" text-color="white" @click="buscarPorFechaYfolio">Buscar</v-btn>
              <v-btn type="button" class="v-btn-geen" color="#009496" text-color="white" @click="limpiarFiltros">Limpiar</v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="info-message">
                <!--v-icon>mdi-information</v-icon>&nbsp;<b>Para lista completa de ordenes de servicio deja los campos en blanco y oprime buscar</b-->
                <div class="info-icon"></div>&nbsp;<b>Para ver lista completa de órdenes de servicio, dejar los campos en blanco y dar clic buscar.</b>

              </div>
            </v-col>
          </v-row>

<v-flex xs12 sm3 class="mx-3 mb-6"></v-flex>
      <v-card-text class="py-4">
        <v-layout row wrap justify-center>
          <v-flex xs12 sm3 class="mx-11 mb-3">
            <v-card class="elevation-1 mb-3" >
              <v-card-title class="headline custom-title-enproceso"> 
                <div class="icon-container" :style="{backgroundColor: '#FF6D00', backgroundImage: `url(/img/PROCESO.png)`, backgroundSize: '30px 30px',
                 backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}">                 
                </div>
                En proceso</v-card-title>
                <v-card-text>
                 <p><b>Total: {{ totals.enProceso }}</b></p>
                </v-card-text>
            </v-card>
          </v-flex>
          <!--v-flex xs12 sm3 class="mx-4 mb-3">
            <v-card class="elevation-1 mb-3">
              <v-card-title class="headline"> <v-icon left class="mr-2 icon-en-proceso">mdi-progress-clock</v-icon>En Proceso</v-card-title>
              <v-card-text>
                <p>Total: {{ totals.enProceso }}</p>
                <p>Porcentaje: {{ percentages.enProceso }}%</p>
              </v-card-text>
            </v-card>
          </v-flex-->
          <v-flex xs12 sm3 class="mx-11 mb-3">
            <v-card class="elevation-1 mb-3">
              <v-card-title class="headline custom-title-terminado"> 
                <div class="icon-container" :style="{ backgroundColor: '#4CAF52',backgroundImage: 'url(/img/TERMINADO.png)',backgroundSize: '30px 30px',
                 backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }">                 
                </div>
                Terminado</v-card-title>
              <v-card-text>
                <p><b>Total: {{ totals.terminado }}</b></p>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>


      <template>
        <v-btn color="primary" to="/dashboard/faq">
          Ir a Preguntas Frecuentes
        </v-btn>
      </template>

      <v-card-text class="py-0">
          <div class="full-width-background">
            <!-- Contenido aquí -->
            <b style="font-size: 19px;">CLIENTE: {{ clientName }} - {{ departmentName }} </b>

          </div>
        <v-text-field
            v-model="searchFolios"

            label="Búsqueda avanzada (equipo, modelo, marca, etc.)"
            single-line
            hide-details
            class="bold-label custom-height"
        >
          <template #prepend>
            <v-icon size="40px">mdi-magnify</v-icon> <!-- Ajusta el tamaño aquí -->
          </template>
        </v-text-field>
        <br>

        <v-data-table
            v-if="!loadingReport"
            :headers="headers"
            :items="reportData || []"
            :search="searchFolios"
           
            :items-per-page="10"
            class="elevation-1 custom-table"
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="gray" icon="mdi-alert">
              No se encontró información con los criterios seleccionados.
            </v-alert>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td><strong @click="openModal(item.folio, item.tipo, item.equipo, item.nombre, item.no_serie)" style="cursor: pointer;">{{ item.folio }}</strong>&nbsp;&nbsp;&nbsp;<v-icon @click="openModal(item.folio, item.tipo, item.equipo, item.nombre, item.no_serie)">mdi-eye</v-icon></td>
              <!--td class="text-center"><v-icon @click="openModal(item.folio, item.tipo, item.equipo, item.nombre, item.no_serie)">mdi-eye</v-icon></td--> 
              <td>{{ item.tipo }}</td>
              <td>{{ item.equipo }}</td>
              <td>{{ item.nombre }}</td>
              <td>{{ item.no_serie }}</td>              
              <td class="text-center">  {{ formatDate(item.fecha_recepcion) }}</td>
              <td class="text-center">
                <div class="icon-text-container">
                  <div v-if="item.estado_proceso === 'EN PROCESO'" class="icon-container-small" 
                      :style="{ backgroundColor: '#FF6D00', backgroundImage: `url(/img/PROCESO.png)` }">
                  </div>

                  <div v-else-if="item.estado_proceso === 'TERMINADA'" class="icon-container-small" 
                      :style="{ backgroundColor: '#4CAF52', backgroundImage: `url(/img/TERMINADO.png)` }">
                  </div>

                  <span :style="{ color: getChipColor(item.estado_proceso) }">
                    {{ item.estado_proceso }}
                  </span>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-flex xs12 sm3 class="mx-3 mb-6"></v-flex>
        <div class="info-message">
                <!--v-icon>mdi-information</v-icon>&nbsp;<b>Para consultar detalle de la orden de servicio dar clic en el número de folio</b-->
                <div class="info-icon"></div>&nbsp;<b>Para consultar detalle de la orden de servicio dar clic en el número de folio</b>
      </div>
        
        <v-layout align-center justify-center>
          <v-container fluid fill-height>

          <v-progress-circular
              v-if="loadingReport"
              :size="50"
              color="primary"
              indeterminate
              class="mb-4"
          ></v-progress-circular>
          <v-card class="elevation-3" v-else>
            <!-- Contenido del card con v-data-table, etc. -->
          </v-card>
          </v-container>

        </v-layout>

      </v-card-text>


    
    </v-card>

    <div class="info-message-1 color-text-green">
      En caso de cualquier duda, favor de comunicarse  al siguiente número o correo, o bien ponerse en contacto con su asesor de ventas
    </div> 
    <div class="contact-info">
  <p>Victor Sánchez</p>
  <p>v.sanchez@grupocormac.com</p>
  <p>Tel: 818333 5535 ext.230</p>
</div>
<v-flex xs12 sm3 class="mx-3 mb-6"></v-flex>


    <!-- Modal -->
    <v-dialog v-model="modalOpen" class="elevation-3" max-width="1200">
      <v-card>
        <v-card-title  class="text-h6 custom-font-size headline white--text custom-header">
          <v-icon class="white--text">mdi-wrench</v-icon>
          &nbsp;&nbsp;
          Folio {{ selectedFolio }} - {{tipo}} - {{equipo}} - {{nombre}} - NS: {{no_serie}}
        </v-card-title>
        <!--v-data-table
            :headers="detailHeaders"
            :items="detailData"
            class="custom-table elevation-1"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.etapa }}</td>

              <td class="d-flex align-center">
                       <span class="d-flex align-center">
                <v-icon v-if="item.nombre_estado === 'CONCLUIDO'" class="mr-2 icon-concluido">mdi-check-circle-outline</v-icon>
                <v-icon v-if="item.nombre_estado === 'EN PROCESO'" class="mr-2 icon-en-proceso">mdi-progress-clock</v-icon>
                <v-icon v-if="item.nombre_estado === 'POR INICIAR'" class="mr-2 icon-por-iniciar">mdi-play-circle-outline</v-icon>

                {{ item.nombre_estado }}
                       </span>
              </td>


              <td>{{ item.fecha_actividad }}</td>
              <td>{{ item.hora_actividad }}</td>
              <td>espacio para información</td>
            </tr>
          </template>
        </v-data-table-->

 



       <div class="timeline-wrapper">
        <v-timeline dense>
          <v-slide-x-transition group>
            <v-timeline-item v-for="(event, index) in detailData" :key="index" :color="getDotColor(event.nombre_estado)" :class="{ 'no-line': index === items.length - 1 }">
              <template v-slot:icon>
                <div class="icon-container">
                  <div class="status-icon-container">
                    <v-icon :class="getIconClass(event.nombre_estado)">{{ getIconClass(event.nombre_estado) }}</v-icon>
                  </div>
                </div>
                <div class="additional-icon-container">
                    <v-icon class="additional-icon" >{{ getAdditionalIcon(event.nombre_estado) }}</v-icon>
                </div>
              </template> 
              <div class="timeline-item-content">
                <div>{{ event.etapa }} - {{ event.nombre_estado }}</div>
                <div>{{ event.fecha_actividad }} {{ event.hora_actividad }}</div>
                <div>{{ event.comunicacion_cliente }}</div>
              </div>
            </v-timeline-item>
          </v-slide-x-transition>
        </v-timeline>
      </div>



        <v-card-actions class="custom-footer">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="modalOpen = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
const API_URL = process.env.VUE_APP_API_URL;
export default {
  name: 'ConsultaFoliosView',
  data() {
    return {
      searchFolios: '',
      no_serie: '',
      reportData: [],
      detailData: [],
      nombre:'',
      equipo:'',
      tipo:'',
      start_date: '',
      end_date: '',
      folio_id:'',
      loadingClientDepartment: true,
      loadingReport: true,
      headers: [
        { text: 'FOLIO CORMAC', value: 'folio' },
        /*{ text: 'Detalle', align: 'center', value: 'detalle', sortable: false },*/ 
        { text: 'TIPO DE EQUIPO', value: 'tipo' },
        { text: 'MODELO', value: 'equipo' },
        { text: 'MARCA', value: 'nombre' },        
        { text: 'NO. DE SERIE', value: 'no_serie' },
        { text: 'FECHA DE ALTA', align: 'center', value: 'fecha_recepcion' },
        { text: 'ESTATUS', align: 'center', value: 'etapa' }
      ],
      detailHeaders: [
        { text: 'Etapa', value: 'etapa'},
        { text: 'Nombre Estado', value: 'nombre_estado' },
        { text: 'Fecha Actividad', value: 'fecha_actividad' },
        { text: 'Hora de Actividad', value: 'hora_actividad'},
        { text: 'Anexos y Eventos', value: 'notificaciones'}
      ],
      modalOpen: false,
      selectedFolio: null,
      selectedFolioDetails: [],
      clientName: '',
      departmentName: '',
      totals: {
        presupuestada: 0,
        enProceso: 0,
        terminado: 0
      },
      percentages: {
        presupuestada: 0,
        enProceso: 0,
        terminado: 0
      },
      error_msg: {
        errorMessage: '',
        reportData: null,
      },
      items: [],
    };
  },
  computed: {
    ...mapState(['accessToken', 'cve_cte', 'cve_entre', 'apiUrl']),
  },
  methods: {
    limpiarFiltros(){
      this.folio_id='';
      this.start_date=''; 
      this.end_date='';
   }, ...mapActions(['logout']), // Asegúrate de que la acción de logout está importada
    async handleLogout() {

      await this.logout(); // Llama a la acción de logout de Vuex
      //this.$router.push({ name: 'LogoutView' });
      // Aquí puedes agregar cualquier lógica adicional, como redireccionar al login
//      if (window.top !== window.self) {
//        window.top.location.href = 'https://www.cor-labs.com.mx'; // Cambia la URL a la de tu página principal
 //     } else {
        // Si no estás en un iframe, redirigir con el router normal
        if (this.$route.path !== '/login') {
          this.$router.push('/login');
        }
      //  this.$router.push({ name: 'LoginView' });

  //    }
    }, formatDate(dateString) {
    // Asegúrate de que la cadena no esté vacía
    if (!dateString) return '';

    // Crear un objeto Date a partir de la cadena
    const date = new Date(dateString);

    // Extraer el día, mes y año
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
    const year = date.getFullYear();

    // Formatear en dd/MM/yyyy
    return `${day}/${month}/${year}`;
  }, async buscarPorFechaYfolio() {
       // Define los parámetros que quieres enviar en la solicitud
        //const clientId = 2; // Reemplaza con el ID del cliente
        //const departmentId = 1; // Reemplaza con el ID del departamento
        //const folio_id = 25344; // Reemplaza con el ID del folio
        //const start_date = '2024-06-04'; // Reemplaza con la fecha de inicio
        //const end_date = '2024-06-04'; // Reemplaza con la fecha de fin
      
      


/*if (!this.folio_id && !this.start_date && !this.end_date) {
  // Caso 1: Si los tres campos están vacíos, llamamos a fetchReport()
  this.fetchReport();
} else if (this.start_date && this.end_date && !this.folio_id) {
  // Caso 3: Si los dos campos de fecha están llenos y el de folio está vacío, ejecutamos el REST
  const url = `/api/monitor-orders/bydate/${this.cve_cte}/${this.cve_entre}/0/${this.start_date}/${this.end_date}`;
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => {
      console.log(response.data);
      this.reportData = response.data;
      this.calculateTotals();
      this.loadingReport = false; 
    })
    .catch(error => {
      console.error('Error fetching report:', error);
    });
} else if (this.folio_id && this.start_date && this.end_date) {
  // Caso 2: Si los tres campos están llenos, ejecutamos el REST
  const url = `/api/monitor-orders/bydate/${this.cve_cte}/${this.cve_entre}/${this.folio_id}/${this.start_date}/${this.end_date}`;
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => {
      console.log(response.data);
      this.reportData = response.data;
      this.calculateTotals();
      this.loadingReport = false;
    })
    .catch(error => {
      console.error('Error fetching report:', error);
    });
}*/


if (!this.folio_id && !this.start_date && !this.end_date) {
  // Caso 1: Si los tres campos están vacíos, llamamos a fetchReport()
  this.fetchReport();
} else if (this.start_date && this.end_date && !this.folio_id) {
  // Caso 2: Si los dos campos de fecha están llenos y el de folio está vacío, ejecutamos el REST

  const url = `${API_URL}/api/monitor-orders/bydate/${this.cve_cte}/${this.cve_entre}/0/${this.start_date}/${this.end_date}`;
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => {
      //this.reportData = response.data;
      this.reportData = Array.isArray(response.data) ? response.data : [];

      if ( this.reportData.length === 0 ) {
//        console.log("No hay datos");
      }
      this.calculateTotals();
      this.loadingReport = false;
    })
    .catch(error => {
      this.error_msg = "No existen datos";
      console.error('Error fetching report:', error);
      this.loadingReport = false; // Aseguramos que el indicador de carga se desactive

    });
} else if (this.folio_id && this.start_date && this.end_date) {
  // Caso 3: Si los tres campos están llenos, ejecutamos el REST
  const url = `${API_URL}/api/monitor-orders/bydate/${this.cve_cte}/${this.cve_entre}/${this.folio_id}/${this.start_date}/${this.end_date}`;
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => {
      //console.log(response.data);
      this.reportData = response.data;
      this.calculateTotals();
      this.loadingReport = false;
    })
    .catch(error => {
      console.error('Error fetching report:', error);
    });
} else if (this.folio_id && !this.start_date && !this.end_date) {
  // Nuevo Caso: Solo folio_id está presente, sin fechas
  //console.log("SOLO FOLIO....")
  const url = `${API_URL}/api/monitor-orders/bydate/${this.cve_cte}/${this.cve_entre}/${this.folio_id}/null/null`;
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => {
      //console.log(response.data);
      this.reportData = response.data;
      this.calculateTotals();
      this.loadingReport = false;
    })
    .catch(error => {
      console.error('Error fetching report:', error);
    });
}
    }, fetchReport() {
      const url = `${API_URL}/api/monitor-orders/grouped/${this.cve_cte}/${this.cve_entre}`;
      axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(response => {
            //console.log("inicio");
            //console.log(response.data);
            this.reportData = response.data;
            this.calculateTotals();
            this.loadingReport = false;
          })
          .catch(error => {
            console.error('Error fetching report:', error);
          });
    },
    calculateTotals() {
      const totalOrders = this.reportData.length;
      //const totalOrdersDetail = this.reportData;
      const estados = {
        presupuestada: 'Presupuestada',
        enProceso: 'EN PROCESO',
        terminado: 'TERMINADA'
      };

      Object.keys(estados).forEach(key => {
        const total = this.reportData.filter(
            item => item.estado_proceso === estados[key]
        ).length;
        //console.log("totals", total);
        this.totals[key] = total;

        this.percentages[key] = ((total / totalOrders) * 100).toFixed(2);
      });
    },
    fetchClientDepartment() {
      //console.log("fetchClientDepartment........",this.cve_cte)
      //console.log("fetchClientDepartment........",this.cve_entre)
      const url = `${API_URL}/api/service-clients-department/${this.cve_cte}/${this.cve_entre}`;
      axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(response => {
            const data = response.data;
            this.clientName = data.nom_cte;
            this.departmentName = data.nom_ent;
            this.loadingClientDepartment = false;

          })
          .catch(error => {
            console.error('Error fetching client department:', error);
          });
    },
    created() {
      this.fetchClientDepartment();
    },
    customFilter(value, search, item) {
      return (
          item.folio.toLowerCase().includes(search.toLowerCase()) ||
          item.tipo.toLowerCase().includes(search.toLowerCase()) ||
          item.no_serie.toLowerCase().includes(search.toLowerCase()) ||
          item.fecha_recepcion.toLowerCase().includes(search.toLowerCase()) ||
          item.estado_general.toLowerCase().includes(search.toLowerCase())
      );
    },
    exportToExcel() {
      const worksheet = XLSX.utils.json_to_sheet(this.reportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Ordenes de Servicio');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(blob, 'serviceOrders.xlsx');
    },
    getChipColor(estado) {
      if (estado === 'TERMINADA') {
        return 'green';
      } else if (estado === 'EN PROCESO') {
        return 'orange';
      }
    }, getIconEstadoProceso(estadoProceso) {
      switch (estadoProceso) {
      case 'TERMINADA':
      return 'mdi-book';
      case 'EN PROCESO':
        return 'mdi-progress-clock'; // Ícono para "EN PROCESO"
    }
  },
    openModal(folio, tipo, equipo, nombre, no_serie) {

      this.selectedFolio = folio;
      this.tipo = tipo;
      this.equipo = equipo;
      this.nombre = nombre;
      this.no_serie = no_serie;

      this.$router.push({ 
        name: 'TimelineView', 
        params: { folio: folio, 
                  tipo: tipo, 
                  equipo:equipo,
                  nombre:nombre,
                  no_serie: no_serie,
                  cve_cte:this.cve_cte,
                  cve_entre:this.cve_entre,
                  clientName:this.clientName 
                } 
      });


      /*const url = `/api/monitor-orders/${this.cve_cte}/${this.cve_entre}/${folio}`;
      axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(response => {
            console.log(response.data)
            this.detailData = response.data;
            this.modalOpen = true;
          })
          .catch(error => {
            console.error('Error fetching folio details:', error);
          });*/


    }, getStageColor(etapa) {
      switch (etapa.toLowerCase()) {
        case 'alta':
          return 'purple';
        case 'en diagnóstico':
          return 'blue';
        case 'terminado':
          return 'green';
        default:
          return 'grey';
      }
    },
    getDotColor(estado) {
      switch (estado) {
        case 'CONCLUIDO':
          return 'green';
        case 'EN PROCESO':
          return 'orange';
        case 'POR INICIAR':
          return 'blue';
        default:
          return 'grey';
      }
    },
    getIconClass(estado) {
      switch (estado) {
        case 'CONCLUIDO':
        return 'mdi-book'; // o 'mdi-check-circle'
      case 'EN PROCESO':
        return 'mdi-cog'; // O el ícono que prefieras
      case 'POR INICIAR':
        return 'mdi-play-circle-outline'; // O el ícono que prefieras
      default:
        return 'mdi-help-circle'; // Un ícono por defecto
      }
    },
    getAdditionalIcon(nombre_estado) {
      switch (nombre_estado) {
    case 'ALTA':
      return '/public/img/ALTA.png'; 
    case 'EN DIAGNÓSTICO':
      return '/public/img/DIAGNOSTICO.png';
    case 'PREPARANDO COTIZACIÓN':
      return '/public/img/PREPARANDO_COTIZACIO╠üN.png';
	case 'COTIZACIÓN EN ESPERA DE AUTORIZACIÓN':
      return '/public/img/COTIZADO_ESPERA.png';
	case 'EN SERVICIO':
      return '/public/img/SERVICIO.png';
	case 'EN PROCESO DE EMBARQUE':
      return '/public/img/PROCESO_ EMBARQUE.png';
	case 'ENVIADO':
    return '/public/img/ENVIADO.png';
	case 'ENTREGADO':
     return '/public/img/ENTREGADO.png';
    default:
      return '/public/img/default.png'; 
  }
    },

  },
  mounted() {
    this.fetchClientDepartment();
    this.fetchReport();
  },
};
</script>

<style scoped>
.elevation-3 {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
</style>

<style scoped>
.no-line .v-timeline-item__before {
  display: none;
}
</style>

<style scoped>
.elevation-3 {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}


.elevation-1 {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}
.text-center {
  text-align: center;
}
.icon-concluido {
  color: green;
}

.icon-en-proceso {
  color: blue;
}

.icon-por-iniciar {
  color: orange;
}
.custom-font-size {
  font-size: 8px;
}
.v-data-table thead th,
.v-data-table tbody td {
  border: none;
}
.custom-color {
  color: rgb(0, 148, 150);
}

.custom-table .v-data-table__wrapper tbody tr.custom-row {
  border-bottom: none !important;
}

.custom-table .v-data-table__wrapper tbody td {
  border-bottom: none !important;
}
.custom-header {
  background-color: #009496 !important;
}
.custom-footer {
  background-color: #009496 !important;
}
.custom-height {
  height: 60px; /* Ajusta la altura a tu preferencia */
}
.icon-container {

  display: flex;
  align-items: center;
  justify-content: space-between; /* Separa los íconos a los extremos del contenedor */
  width: 100%; /* Ocupa todo el ancho disponible */
}

.additional-icon {
  position: absolute;
  left: 0; /* Alinea el ícono adicional a la izquierda */
  margin-left: 8px; /* Espacio entre el ícono adicional y el borde izquierdo */
 }

.v-icon {
  font-size: 24px; /* Tamaño del ícono del estado */
}
.timeline-wrapper {
  margin-left: 10%; /* Espacio a la derecha del contenedor del timeline */
}
.status-icon {
  position: absolute;
  left: 50%; /* Centra el icono en el contenedor */
  transform: translateX(-50%); /* Ajusta la posición para que esté exactamente en el centro */
}

.timeline-item-content {
  margin-left: 10px; /* Espacio entre el icon-container y el contenido del timeline-item */
}
.icon-wrapper {
  display: flex;
  align-items: center;
  width: 100%; /* Asegura que el contenedor ocupe todo el espacio disponible */
  position: relative; /* Permite usar `position: absolute` en los íconos internos */
}
.additional-icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Alinea el ícono adicional a la izquierda */
  margin-right: 140px; /* Espacio entre el ícono adicional y el ícono del estado */
}
.status-icon-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Centra el ícono del estado dentro del círculo */
  width: 40px; /* Ajusta el tamaño del contenedor del ícono del estado */
  height: 40px;
  background-color: var(--v-theme-primary); /* Color de fondo opcional */
  border-radius: 50%; /* Hace el contenedor circular */
}
.left-content {
  display: flex;
  align-items: center;
  margin-right: 16px; /* Espacio entre el contenido de la izquierda y el ícono */
}

.search-panel {
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  width: 70%;
  margin: 0 auto; /* Centrar el panel */
  position: relative;
  align-items: center; 
  display: flex;
  flex-direction: column;
}

.panel-title {
  position: absolute;
  top: -14px;
  left: 15px;
  padding: 0 10px;
  font-size: 18px;
  font-weight: bold;
  color: #009496;
  background-color: #fff; /* Fondo blanco para el texto del título */
}

.form-group {
  display: flex;
  align-items: center; /* Alinea los elementos verticalmente */
  margin-bottom: 20px; /* Espacio entre grupos de campos */
  width: 100%;
  max-width: 364px;
  margin-bottom: -4px;
}

.form-group-al {
  display: flex;
  align-items: center; /* Alinea los elementos verticalmente */
  margin-bottom: 20px; /* Espacio entre grupos de campos */
  width: 100%;
  max-width: 250px;
  margin-bottom: -4px;
  justify-content: flex-start; /* Alinea todo a la izquierda */
}

.form-group-al label {
  margin-right: 15px; /* Espacio entre el label y el input */
  width: auto; /* Permite que el label ocupe solo el espacio necesario */
  text-align: left; /* Alinea el texto del label a la izquierda */
}
.form-group label {
  margin-right: 15px; 
  width: 200px; 
  text-align: right; 
}

.form-group input {
  padding: 10px;
  font-size: 16px;
  /*border-radius: 5px;*/
  /*border: 1px solid #ccc;*
  width: calc(100% - 215px); /* Ajusta el ancho del campo para que se ajuste al espacio disponible */
  max-width: 400px; /* Ancho máximo del campo de entrada */
}
.form-row {
  display: flex;
  justify-content: space-between;
   gap: 200px;   
}

.form-row-folio {
  display: flex;
  justify-content: space-between;
   gap: 200px;  
   margin-left: -11px; /* Ajusta este valor para mover el row de folio a la izquierda */
 
}

.form-row .form-group {
  flex: 1;
  margin-right: 10px;
}

.form-row .form-group:last-child {
  margin-right: 0;
}

.form-buttons {
  display: flex;
  justify-content: center; /* Centrar los botones */
  /*margin-top: 20px;*/
  gap: 140px;
}

.bold-label .v-label {
  font-weight: bold; /* Pone el texto del label en negritas */
  font-size: 30px;   /* Opcional: cambia el tamaño del texto */
}


.btn {
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 10px;
}

/*.btn-primary {
  background-color: #009496;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-secondary {
  background-color: #009496;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}*/

.v-btn-geen {
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 10px;
  border-radius: 30px; 
  color: white !important;
  background-color: #009496; 
  border: none;
  cursor: pointer;
}

.info-message {
  display: flex;
  align-items: center; /* Centra el contenido verticalmente */
  justify-content: center; /* Centra el contenido horizontalmente */
  font-size: 16px; /* Tamaño de la fuente del texto */
  color: #333; /* Color del texto */
  padding: 10px;
   /*border: 1px solid #ccc; Borde opcional */
  border-radius: 5px; /* Esquinas redondeadas opcionales */
  background-color: white; /* Fondo opcional */
  /*max-width: 600px; Ancho máximo opcional */
  margin: 0 auto; /* Centra el div en el contenedor */
  text-align: justify;
}

.info-message-1 {
  display: flex; /* Usar flexbox para alinear los elementos hijos */
  align-items: center; /* Centra el contenido verticalmente */
  justify-content: center; /* Centra el contenido horizontalmente */
  font-size: 16px; /* Tamaño de la fuente del texto */
  color: #333; /* Color del texto */
  padding: 10px;
  border-radius: 5px; /* Esquinas redondeadas opcionales */
  background-color: white; /* Fondo opcional */
  margin: 20px auto; /* Centra el div horizontalmente en su contenedor padre */
  text-align: center; /* Centra el texto dentro del div */
  max-width: 600px; /* Ancho máximo opcional para controlar el tamaño */
}

.info-message i {
  font-size: 18px; /* Tamaño del ícono */
  color: yellow; /* Color del ícono */
  margin-right: 10px; /* Espacio entre el ícono y el texto */
}
.info-icon {
  width: 30px; /* Ajusta el tamaño del contenedor */
  height: 30px; /* Ajusta el tamaño del contenedor */
  margin-right: 10px; /* Espacio entre el icono y el texto */
  background: url('/public/img/INFO.png') no-repeat center center;
  /*background-size: 70%; /* Ajusta el tamaño de la imagen de fondo para que encaje bien dentro del círculo */
  background-color: #FFC107; /* Color de fondo del contenedor */
  border-radius: 50%; /* Hace el contenedor redondo */
  display: inline-block; /* Para asegurar el correcto comportamiento de la alineación */
  line-height: 0; /* Elimina cualquier espacio extra generado por la altura de la línea */
  padding: 5px; /* Añade un pequeño espacio alrededor del icono */
  box-sizing: border-box; /* Asegura que el padding no cambie el tamaño total del elemento */
}

.custom-title-enproceso {
  color: #FF6D00; /* Cambia este valor por el color que desees */
  display: flex;
  align-items: center;
}
.custom-title-terminado {
  color: #4CAF52; /* Cambia este valor por el color que desees */
  display: flex;
  align-items: center;
}

.color-text-green {
  color: #009496;
}

.contact-info {
  background-color: #009496; /* Color de fondo */
  color: white;              /* Color del texto */
  border-radius: 40px;        /* Esquinas redondeadas */
  padding: 20px;             /* Espaciado interno */
  max-width: 300px;          /* Ancho máximo opcional */
  margin: 0 auto;            /* Centrar el div si es necesario */
}

.contact-info p {
  margin: 0; /* Eliminar margen por defecto en los párrafos */
  line-height: 1.5; /* Espaciado entre líneas */
  text-align: center;
}

.icon-container {
  width: 40px; /* Ancho del contenedor circular */
  height: 40px; /* Alto del contenedor circular */
  border-radius: 50%; /* Hace que el contenedor sea circular */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.icon-text-container {
  display: flex;
  align-items: center;  /* Alinea el ícono y el texto verticalmente */
}

.icon-container-small {
  width: 20px;  /* Ajusta el tamaño del icono */
  height: 20px;
  background-size: cover;
  border-radius: 50%;
  margin-right: 8px;  /* Espacio entre el icono y el texto */
}

.icon-container i {
  color: white; /* Color del icono */
  width: 40px; /* Ancho del contenedor circular */
  height: 40px; /* Alto del contenedor circular */
  border-radius: 50%; /* Hace que el contenedor sea circular */
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover; /* Ajusta la imagen para que cubra el contenedor */
  background-position: center; /* Centra la imagen dentro del contenedor */
  margin-right: 10px; /* Espacio entre el icono y el texto */
}

.header-container {
  display: flex;
  align-items: center; /* Alinea verticalmente el contenido */
  background-color: #009496; /* Color de fondo */
  color: white; /* Color de texto */
  padding: 10px; /* Espaciado interno */
  width: 100%; /* Ocupa todo el ancho del contenedor padre */
  box-sizing: border-box; /* Incluye padding en el cálculo del ancho */
}

.headline {
  font-size: 16px; /* Tamaño de la fuente */
}


.full-width-background {
  border-radius: 18px;
  padding: 20px;
  background-color: #009496;
  border: 1px solid #ddd;
  display: flex;
  justify-content: space-between; /* Mantiene el texto a la izquierda y la imagen a la derecha */
  align-items: center; /* Centra verticalmente los elementos */
  color: white;
}

.custom-icon-size .v-input__prepend-inner .v-icon {
  font-size: 84px; /* Ajusta este valor al tamaño deseado */
}

</style>
