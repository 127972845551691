<template>
  <v-container>
    <v-card class="elevation-3">
      <v-card-title class="custom-bg">
        <span class="headline white--text">Carga de Archivos</span>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="uploadFile" enctype="multipart/form-data">
          <v-text-field
              v-model="folio"
              label="Folio"
              required
          ></v-text-field>

          <v-select
              v-model="etapa"
              :items="etapas"
              label="Etapa"
              required
          ></v-select>

          <v-file-input
              v-model="archivo"
              label="Archivo"
              prepend-icon="mdi-paperclip"
              required
          ></v-file-input>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="resetForm">
              <v-icon left>mdi-close</v-icon> Cancelar
            </v-btn>
            <v-btn text type="submit">
              <v-icon left>mdi-content-save</v-icon> Subir Archivo
            </v-btn>
          </v-card-actions>

          <v-alert
              v-if="message"
              :type="messageType"
              class="mt-4"
              dismissible
          >
            {{ message }}
          </v-alert>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL;
export default {
  data() {
    return {
      folio: '',
      etapa: '',
      archivo: null,
      message: '',
      messageType: 'success',
      etapas: [
        'ALTA',
        'EN DIAGNÓSTICO',
        'PREPARANDO COTIZACIÓN',
        'COTIZACIÓN EN ESPERA DE AUTORIZACIÓN',
        'EN SERVICIO',
        'EN PROCESO DE EMBARQUE',
        'ENVIADO',
        'ENTREGADO'
      ],
    };
  },
  methods: {
    async uploadFile() {
      if (!this.archivo) {
        this.message = 'Por favor seleccione un archivo';
        this.messageType = 'error';
        return;
      }

      const formData = new FormData();
      formData.append('folio', this.folio);
      formData.append('etapa', this.etapa);
      formData.append('archivo', this.archivo); // Archivo se toma directamente desde el v-file-input

      try {
        const response = await axios.post(`${API_URL}/api/carga-archivos`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        this.message = 'Archivo cargado correctamente';
        this.messageType = 'success';
        console.log(response.data);
      } catch (error) {
        console.error('Error en carga de archivos:', error);
        this.message = 'Error al subir archivo';
        this.messageType = 'error';
      }
    },
    resetForm() {
      this.folio = '';
      this.etapa = '';
      this.archivo = null;
      this.message = '';
    },
  },
};
</script>

<style scoped>
.v-card {
  max-width: 600px;
  margin: 0 auto;
}

.v-card-title {
  justify-content: center;
}
.custom-bg {
  background-color: #009496;
}
.v-btn {
  margin-left: 10px;
}
</style>
